import ApiService from "@/core/services/api.service";

// action types
export const EMAIL_AUTH = "emailAuth";
export const POST_AUTH = "postAuth";
export const SET_LOADING_LOGIN = "setLoadingLogin";

// mutation types
export const SET_PROCESS = "setProcess";
export const SET_ERROR = "setError";
export const SET_IS_EMAIL_AUTH = "setEmailAuth";
export const SET_SUCCESS_ACTION = "setSuccessAction";

const state = {
  errors: null,
  process: null,
  isLoading: false,
  isSuccess: false,
  isEmailAuth: false
};

const getters = {
  getLoadingInfo: state => {
    return state.isLoading;
  },
  getError: state => {
    return state.errors;
  },
  getSuccessStatus: state => {
    return state.isSuccess;
  },
  getEmailAuth: state => {
    return state.isEmailAuth;
  }
};

const actions = {
  [EMAIL_AUTH](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING_LOGIN, true);
      ApiService.post("/api/smartmap/register/check_emailexist", {
          email: credentials.email
        })
          .then(({ data }) => {
            context.commit(SET_IS_EMAIL_AUTH, data.data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
            context.commit(SET_IS_EMAIL_AUTH, false);
            context.commit(SET_LOADING_LOGIN, false);
            reject(response);
          });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    if (!error) return;
    state.errors = error;
    if (error.status === 422 && error.data.message === "unauthenticated") state.errors = "MESSAGES.AUTH.INVALID_ACCOUNT";
    if (error.status === 401) state.errors = "MESSAGES.AUTH.INVALID_ACCOUNT";
    if (error.status === 500) state.errors = "MESSAGES.SERVER_ERROR";
  },
  [SET_LOADING_LOGIN](state, status) {
    state.isLoading = status;
  },
  [SET_SUCCESS_ACTION](state, data) {
    state.isSuccess = data;
  },
  [SET_IS_EMAIL_AUTH](state, data) {
    state.isEmailAuth = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
