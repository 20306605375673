<template>
  <div>
    <!--begin::Content header-->
    <div
      class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
    >
      <router-link
          class="font-weight-bold font-size-3 ml-2"
          :to="{ name: 'login' }"
      >
        {{ $t("AUTH.LOGIN.BUTTON") }}
      </router-link>
    </div>
    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-10 mb-lg-20">
        <img :src="logo" class="max-w-400px align-center" alt="" />
        <br /><br /><br /><br />
        <h3 class="font-size-h1">{{ $t("AUTH.FORGOT.TITLE") }}</h3>
        <p class="text-muted font-weight-semi-bold">
          {{ $t("AUTH.FORGOT.DESC") }}
        </p>
      </div>

<!--      <div v-if="successEmail === false">-->
        <!--begin::Form-->
        <b-form class="form" @submit.stop.prevent="onSubmitData">
          <div
              role="alert"
              v-bind:class="[errors === '' ? '' : 'alert alert-danger']"
          >
            <div class="alert-text">
              {{ $t(errors) }}
            </div>
          </div>
          <div
              role="alert"
              v-bind:class="[processs === '' ? '' : 'alert alert-success']"
          >
            <div class="alert-text">
              {{ $t(processs) }}
            </div>
          </div>

          <b-form-group
              id="example-input-group-1"
              label=""
              label-for="example-input-1"
          >
            <b-form-input
                class="form-control form-control-solid h-auto py-5 px-6"
                id="example-input-1"
                name="example-input-1"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
                placeholder="Email"
                aria-describedby="input-1-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">
              {{ $t("AUTH.VALIDATION.INVALID", { name: "Email" }) }}.
            </b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Action-->
          <div
              class="form-group d-flex flex-wrap justify-content-between align-items-center"
          >
            <button
                type="submit"
                ref="kt_forgot_submit"
                class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
                v-bind:class="[
                  loading ? 'spinner spinner-light spinner-right' : ''
                ]"
                :disabled="loading != ''"
            >
              SUBMIT
            </button>
            <button
                class="btn btn-secondary font-weight-bold px-9 py-4 my-3 font-size-3"
                @click="cancelForm"
            >
              Cancel
            </button>
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->
<!--      </div>-->

<!--      <div v-if="successEmail === true">-->
<!--        &lt;!&ndash;begin::Form&ndash;&gt;-->
<!--        <b-form class="form" @submit.stop.prevent="onSubmitConfirm">-->
<!--          <div-->
<!--              role="alert"-->
<!--              v-bind:class="[errors === '' ? '' : 'alert alert-danger']"-->
<!--          >-->
<!--            <div class="alert-text">-->
<!--              {{ $t(errors) }}-->
<!--            </div>-->
<!--          </div>-->

<!--          <b-form-group-->
<!--              id="password-group"-->
<!--              label="Password"-->
<!--              label-for="password"-->
<!--          >-->
<!--            <b-form-input-->
<!--                class="form-control form-control-solid h-auto py-5 px-6"-->
<!--                type="password"-->
<!--                id="password"-->
<!--                name="password"-->
<!--                v-model="$v.form.password.$model"-->
<!--                :state="validateState('password')"-->
<!--                aria-describedby="password-live-feedback"-->
<!--                placeholder="Password"-->
<!--            ></b-form-input>-->
<!--            <b-form-invalid-feedback id="password-live-feedback">-->
<!--              Password is required.-->
<!--            </b-form-invalid-feedback>-->
<!--          </b-form-group>-->
<!--          <b-form-group-->
<!--              id="password-confirm-group"-->
<!--              label="Confirm Password"-->
<!--              label-for="confirmpassword"-->
<!--          >-->
<!--            <b-form-input-->
<!--                class="form-control form-control-solid h-auto py-5 px-6"-->
<!--                type="password"-->
<!--                id="passwordconfirm"-->
<!--                name="passwordconfirm"-->
<!--                v-model="$v.form.passwordconfirm.$model"-->
<!--                :state="validateState('passwordconfirm')"-->
<!--                aria-describedby="password-confirm-live-feedback"-->
<!--                placeholder="Confirm Password"-->
<!--            ></b-form-input>-->

<!--            <b-form-invalid-feedback id="password-live-feedback">-->
<!--              Password unmatch.-->
<!--            </b-form-invalid-feedback>-->
<!--          </b-form-group>-->

<!--          &lt;!&ndash;begin::Action&ndash;&gt;-->
<!--          <div-->
<!--              class="form-group d-flex flex-wrap justify-content-between align-items-center"-->
<!--          >-->
<!--            <button-->
<!--                ref="kt_login_signin_submit"-->
<!--                class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"-->
<!--                v-bind:class="[-->
<!--              loading ? 'spinner spinner-light spinner-right' : ''-->
<!--            ]"-->
<!--            >-->
<!--              {{ $t("AUTH.LOGIN.BUTTON") }}-->
<!--            </button>-->
<!--            <button-->
<!--                class="btn btn-secondary font-weight-bold px-9 py-4 my-3 font-size-3"-->
<!--                @click="cancelForm"-->
<!--            >-->
<!--              Cancel-->
<!--            </button>-->
<!--          </div>-->
<!--          &lt;!&ndash;end::Action&ndash;&gt;-->
<!--        </b-form>-->
<!--        &lt;!&ndash;end::Form&ndash;&gt;-->
<!--      </div>-->

    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import {
  EMAIL_AUTH,
  SET_ERROR, SET_IS_EMAIL_AUTH,
  SET_LOADING_LOGIN,
  SET_SUCCESS_ACTION
} from "@/core/services/store/forgotPassword.module";

import { validationMixin } from "vuelidate";
import {email, minLength, required, sameAs} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import Swal from 'sweetalert2'
import * as axios from "axios";

export default {
  isLoading: false,
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      successEmail : false,
      // Remove this dummy login info
      form: {
        email: "",
        password: "",
        passwordconfirm: ""
      },
      loading: false,
      successAction : false,
      errors: "",
      processs: "",
      logo: process.env.BASE_URL + "media/logos/komnasham.png"
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      },
      passwordconfirm: {
        required,
        sameAsPassword: sameAs("password")
      },
    }
  },
  mounted() {
    this.subscribes();
  },
  methods: {
    subscribes() {
      this.unsubscribe = this.$store.subscribe(mutation => {
        if (mutation.type === SET_LOADING_LOGIN) {
          this.loading = this.getLoadingInfo;
        }
        if (mutation.type === SET_SUCCESS_ACTION) {
          this.successAction = this.getSuccessStatus;
        }
        if (mutation.type === SET_ERROR) {
          this.errors = this.getError;
        }
        if (mutation.type === SET_IS_EMAIL_AUTH) {
          this.successEmail = this.getEmailAuth;
        }
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    cancelForm() {
      this.$router.push("login");
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmitData() {
      this.$v.form.$touch();
      this.loading = true;
      if (this.$v.form.$anyError) {
        this.loading = this.getLoadingInfo;
      }
      const email = this.$v.form.email.$model;

      this.errors = "";
      this.processs = "Auth email address...";
      this.$store.dispatch(EMAIL_AUTH, { email })
        .then(() => {
          if(this.successEmail == false){
            this.loading = false;
            this.processs = "";
            this.errors = "Email is not registered";
            Swal.fire(
                'Forgot Password',
                'Email is not registered',
                'error'
            );
          }else{
            this.loading = true;
            this.processs = "Success auth email address";
            this.processs = "Sending email...";
            this.errors = "";
            axios({
              url:
                  process.env.VUE_APP_API_BASE_URL + "api/forgot-password",
              method: "POST",
              data : { email }
            })
            .then((respond) => {
              this.loading = false;
              this.processs = "Get process email";
              if(respond.data.status == 0){
                this.processs = "Email sent successfully";
                Swal.fire(
                    'Success Send Email',
                    'Please check inbox or spam',
                    'success'
                );
              }else{
                this.loading = false;
                this.processs = "";
                this.errors = "failed email send";
                console.log(respond);
                Swal.fire(
                    'failed Send Email',
                    'Failed send email, please contact administrator',
                    'error'
                );
              }
            }).catch((error) => {
              this.loading = false;
              this.processs = "";
              this.errors = "failed email send";
              console.log(error);
              Swal.fire(
                  'failed Send Email',
                  'Failed send email, please contact administrator',
                  'error'
              );
            });
          }
        });
      this.successEmail = false;
    }
  },
  computed: mapGetters(["getLoadingInfo", "getError", "isAuthenticated", "getEmailAuth"])
};
</script>
